import Vue from 'vue';
import VueRouter from 'vue-router';

// import ItemDetail from '@/views/ItemDetail.vue';

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue'),
  meta: {
    // title: 'DiiP Group | Digital Impression Industry Partners' // 标题设置
  }
},
{
  path: '/about',
  name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  meta: {
    // title: 'DiiP Group | Digital Impression Industry Partners' // 标题设置
  },
  component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
},
{
  path: '/product/:cate',
  name: 'Product',
  component: () => import( /* webpackChunkName: "about" */ '../views/Products.vue')

},
{
  path: '/application/:cate',
  name: 'Application',
  component: () => import( /* webpackChunkName: "about" */ '../views/Application.vue')
},

{
  path: '/productDetail/:id',
  name: 'ProductDetail',
  component: () => import( /* webpackChunkName: "about" */ '../views/ProductDetail.vue')

},


{
  path: '/certification',
  name: 'Certification',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  meta: {
    // title: 'DiiP Group | Digital Impression Industry Partners' // 标题设置
  },
  component: () => import( /* webpackChunkName: "about" */ '../views/Certification.vue')
},


{
  path: '/partners',
  name: 'Partners',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  meta: {
    // title: 'DiiP Group | Digital Impression Industry Partners' // 标题设置
  },
  component: () => import( /* webpackChunkName: "about" */ '../views/Partners.vue')
},
{
  path: '/service',
  name: 'Services',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  meta: {
    // title: 'DiiP Group | Digital Impression Industry Partners' // 标题设置
  },
  component: () => import( /* webpackChunkName: "about" */ '../views/Services.vue')
},
// {
//   path: '/app',
//   name: 'App',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   meta: {
//     // title: 'DiiP Group | Digital Impression Industry Partners' // 标题设置
//   },
//   component: () => import( /* webpackChunkName: "about" */ '../views/App.vue')
// },
{
  path: '/contact',
  name: 'ContactUs',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  meta: {
    // title: 'DiiP Group | Digital Impression Industry Partners' // 标题设置
  },
  component: () => import( /* webpackChunkName: "about" */ '../views/ContactUs.vue')
},
{
  path: '/news',
  name: 'News',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  meta: {
    // title: 'DiiP Group | Digital Impression Industry Partners' // 标题设置
  },
  component: () => import( /* webpackChunkName: "about" */ '../views/News.vue')
},

{
  path: '/news/:id',
  name: 'NewsPage',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  meta: {
    // title: 'DiiP Group | Digital Impression Industry Partners' // 标题设置
  },
  component: () => import( /* webpackChunkName: "about" */ '../views/NewsPage.vue')
  },
  {
    path: '/pages/:id',
    name: 'ExtraPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      // title: 'DiiP Group | Digital Impression Industry Partners' // 标题设置
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/ExtraPages.vue')
  },

{
  path: '/search/:keyword',
  name: 'Search',
  component: () => import( /* webpackChunkName: "about" */ '../views/Search.vue')

},


]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router