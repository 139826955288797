import 'fullpage.js/vendors/scrolloverflow'; // Optional. When using scrollOverflow:true
import 'swiper/css/swiper.css';
import Video from 'video.js';
import 'video.js/dist/video-js.css';
import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueFullPage from 'vue-fullpage.js';
import VueWechatTitle from 'vue-wechat-title';
import App from './App.vue';
import './plugins/element.js';
import router from './router';
import store from './store';
Vue.use(VueWechatTitle)
Vue.prototype.$video = Video
 
import 'vue-social-sharing/dist/vue-social-sharing.min';

import { uniBars, uniSearch } from 'vue-unicons/dist/icons';
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd';
Unicon.add([uniBars, uniSearch])
Vue.use(Unicon)


var SocialSharing = require('vue-social-sharing')
Vue.use(SocialSharing)

import UUID from 'vue-uuid';
import './utils/rem';
 
Vue.use(UUID);
Vue.use(VueFullPage);
Vue.use(VueAwesomeSwiper, {})
Vue.config.productionTip = false

router.afterEach(() => {
  if (document.getElementsByClassName("fp-scroller")[0]) {
    document.getElementsByClassName("fp-scroller")[0].style.transform = 'translate(0px, 0px) translateZ(0px)'
  }

})

Vue.directive('disable-right-click', {
  inserted: function (el) {
    el.addEventListener('contextmenu', function(e) {
      e.preventDefault();
    });
  }
});




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')